import React, { FC, useContext, useState } from 'react'
import { Box, Menu, MenuItem, Tooltip } from '@mui/material'
import ExportPDF from 'components/ExportPDF'
// Contexts
import { AuthContext } from 'context/AuthContext'
// Types
import { PbiTab, ReportConfig } from 'types/report'
// Styles
import * as Styled from './NavigationBar.styled'

interface Props {
    tabs: PbiTab[]
    onChangeTab: (val: PbiTab) => void
}

const NavigationTabs: FC<Props> = ({ tabs, onChangeTab }) => {
    const { showPdfExport } = useContext<ReportConfig>(AuthContext)
    const [currentTab, setCurrentTab] = useState(tabs[0].pageName)
    const [subMenuTab, setSubMenuTab] = useState<PbiTab>()
    const [anchorEl, setAnchorEl] = useState<Element>()

    const handleChangeTab = (event: React.SyntheticEvent<Element, Event>, pageName: string) => {
        const selectedTab = tabs.find((tab: PbiTab) => tab.pageName === pageName)
        if (!selectedTab) {
            return
        }
        setCurrentTab(pageName)
        onChangeTab(selectedTab)
    }

    const openSubMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, tab: PbiTab) => {
        setSubMenuTab(tab)
        setAnchorEl(event.currentTarget)
    }

    const closeSubMenu = () => {
        setAnchorEl(undefined)
    }

    const navigateToSubTab = (tab: PbiTab) => {
        setCurrentTab(subMenuTab?.name)
        closeSubMenu()
        onChangeTab(tab)
    }

    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Styled.Tabs value={currentTab} onChange={handleChangeTab} aria-label="powerBI-tabs">
                {tabs.map((tab: PbiTab) => {
                    const navigableTab = !!tab.pageName
                    const label = `${tab.name}${navigableTab ? '' : ' ▾'}`
                    return (
                        <Styled.Tab
                            key={tab.pageName || tab.name}
                            value={tab.pageName || tab.name}
                            label={label}
                            {...(!navigableTab && { onClick: (event) => openSubMenu(event, tab) })}
                            {...(tab.tooltip && {
                                icon: (
                                    <Tooltip
                                        arrow
                                        placement='bottom'
                                        title={tab.tooltip.split('\n').map((line, index) => (
                                            <div key={index}>{line}</div>
                                        ))}
                                    >
                                        <Styled.InfoIcon />
                                    </Tooltip>
                                ),
                                iconPosition: 'end',
                            })}
                        />
                    )
                })}
                {showPdfExport && <ExportPDF />}
            </Styled.Tabs>

            <Menu open={!!anchorEl && !!subMenuTab} anchorEl={anchorEl} onClose={closeSubMenu}>
                {subMenuTab?.tabs &&
                    Object.values(subMenuTab.tabs).map((tab: PbiTab) => (
                        <MenuItem key={tab.pageName} onClick={() => navigateToSubTab(tab)}>
                            {tab.name}
                        </MenuItem>
                    ))}
            </Menu>
        </Box>
    )
}

export default NavigationTabs
