import styled from '@emotion/styled'
import { Tab as MuiTab, Tabs as MuiTabs } from '@mui/material'
import InfoOutlined from '@mui/icons-material/InfoOutlined'

export const Tabs = styled(MuiTabs)`
    height: 48px;
`

export const Tab = styled(MuiTab)`
    text-transform: none;
    ${({ icon }) => icon && `margin-top: -12px;`}
`

export const InfoIcon = styled(InfoOutlined)`
    width: 0.5em;
    height: 0.5em;
`
